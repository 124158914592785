export default TheUltimateImageEnhancementTools;

import {
  Anchor,
  Button,
  Container,
  Flex,
  Group,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import React from "react";

import SharpenPortraits from "../../static/tools/Sharpen-Portraits.webp";
import ColorCorrection from "../../static/tools/Color-Correction.webp";
import UnblurImages from "../../static/tools/Unblur-Images.webp";
import ImageRestoration from "../../static/tools/Image-Restoration.webp";

import "@mantine/core/styles/Group.css";
import "@mantine/core/styles/Stack.css";
import "@mantine/core/styles/Container.css";
import "@mantine/core/styles/Paper.css";
import "@mantine/core/styles/Card.css";
import "@mantine/core/styles/Anchor.css";
import "@mantine/core/styles/SimpleGrid.css";

function TheUltimateImageEnhancementTools() {
  return (
    <Group justify="center" align="center" py={60}>
      <Container w="100%" className="section-container" size="xl">
        <Stack justify="center" align="center">
          <Title c="#FFF" ta="center" order={2} maw={880}>
            The Ultimate Tools For Pixel Perfect Images
          </Title>

          <Stack w="100%" mt={60}>
            <SimpleGrid
              cols={{
                base: 1,
                sm: 2,
                md: 2,
              }}
              spacing={{
                base: 20,
                sm: 50,
              }}
            >
              {/* <Stack mb={60}>
                <Anchor>
                  <Title order={3}>Enhance Photos</Title>
                </Anchor>
                <Text>
                  Enhance your photos with ImgGen's free AI photo enhancer.
                  Upload low-quality images then watch our intelligent tech
                  magically upscale and perfect them in seconds.
                </Text>

                <Button
                  mt={6}
                  w="max-content"
                  variant="light"
                  rightSection={<IconArrowNarrowRight />}
                >
                  Enhance Photo
                </Button>
              </Stack> */}
              <Stack mb={20}>
                <Image
                  src={SharpenPortraits}
                  w="auto"
                  alt="Sharpen Portraits"
                />

                <Anchor href="/tools/sharpen-portraits">
                  <Title order={3}>Sharpen Portraits Photos</Title>
                </Anchor>
                <Text>
                  Instantly sharpen and refine portraits. Our AI sharpens facial
                  details for crisp, clear photos in seconds. Get
                  magazine-quality shots with just one click.
                </Text>

                <Button
                  mt={6}
                  component="a"
                  w="max-content"
                  variant="light"
                  rightSection={<IconArrowNarrowRight />}
                  href="/tools/sharpen-portraits"
                >
                  Sharpen Photos
                </Button>
              </Stack>
              <Stack mb={20}>
                <Image
                  w="auto"
                  src={ImageRestoration}
                  alt="Image Restoration"
                />
                <Anchor href="/tools/image-restoration">
                  <Title order={3}>Restore Old Photos</Title>
                </Anchor>
                <Text>
                  Revive damaged, faded photos. Our AI restores old images by
                  removing scratches, blur and other defects for free. Bring new
                  life to vintage family photos.
                </Text>

                <Button
                  mt={6}
                  component="a"
                  w="max-content"
                  variant="light"
                  rightSection={<IconArrowNarrowRight />}
                  href="/tools/image-restoration"
                >
                  Restore Old Photo
                </Button>
              </Stack>
              <Stack mb={20}>
                <Image src={ColorCorrection} w="auto" alt="Color Correction" />
                <Anchor href="/tools/color-correction">
                  <Title order={3}>Color Correction</Title>
                </Anchor>
                <Text>
                  Adjust color and lighting with ease. Our AI corrects colors,
                  balances light, and fixes issues in one click. Get
                  professional photo edits and enhancements with no effort.
                </Text>

                <Button
                  mt={6}
                  component="a"
                  w="max-content"
                  variant="light"
                  rightSection={<IconArrowNarrowRight />}
                  href="/tools/color-correction"
                >
                  Color Correction
                </Button>
              </Stack>
              <Stack mb={20}>
                <Image src={UnblurImages} w="auto" alt="Unblur Images" />
                <Anchor href="/tools/unblur-image">
                  <Title order={3}>Unblur Images</Title>
                </Anchor>
                <Text>
                  Sharpen blurry images fast. Our AI deblur technology brings
                  blurred photos back into focus within seconds. Clear up
                  grainy, out-of-focus shots in an instant. Rescue photos ruined
                  by camera shake or motion blur.
                </Text>

                <Button
                  mt={6}
                  component="a"
                  w="max-content"
                  variant="light"
                  rightSection={<IconArrowNarrowRight />}
                  href="/tools/unblur-image"
                >
                  Unblur Photo
                </Button>
              </Stack>
            </SimpleGrid>

            <Flex justify="center" mt="md">
              <Button component="a" href="/tools" size="lg" w="max-content">
                View All Tools
              </Button>
            </Flex>
          </Stack>
        </Stack>
      </Container>
    </Group>
  );
}
